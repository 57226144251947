import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agentData: {},
};

const agentDataSlice = createSlice({
  name: "agentInfo",
  initialState,
  reducers: {
    setAgentData: (state, action) => {
      state.agentData = action.payload;
    },
  },
});

export const { setAgentData } = agentDataSlice.actions;

export default agentDataSlice.reducer;
